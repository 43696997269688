import '../../../components/nova-flow/nova-flow/nova-flow.js';
import '../../../components/nova-flow/nova-flow-step/nova-flow-step.js';
import './steps/client-onboarding-industry/client-onboarding-industry.js';
import './steps/client-onboarding-department/client-onboarding-department.js';
import './steps/client-onboarding-focus/client-onboarding-focus.js';

import { html, LitElement } from 'lit';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaNavMixin } from '../../../../shared/mixins/nova-nav/nova-nav.js';
import { OBJECTIVES } from './steps/client-onboarding-objective/client-onboarding-objective.js';

const STEPS = {
  START: 'welcome',
  OBJECTIVE: 'objective',
  INDUSTRY: 'industry',
  DEPARTMENT: 'department',
  FOCUS: 'focus',
  END: 'end',
};

export default class ViewClientOnboarding extends LocalizeNova(RequesterMixin(NovaNavMixin(LitElement))) {

  static get properties() {
    return {
      _companyName: { type: String },
      _lightcastCompanyId: { type: String },
    };
  }

  constructor() {
    super();
    this._companyName = '';
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');

    this._companyName = this.session.tenant.name;
    // TODO: Leaving this for testing, will remove later
    this._companyName = 'D2L';
    const lightcastCompanyData = await this.client.searchLightcast(this._companyName);
    if (lightcastCompanyData.length) {
      this._lightcastCompanyId = lightcastCompanyData[0].id;
    }
  }

  _completedFlow() {
    this.navigateWithoutHistory('/');
  }

  _shouldSkipDepartment(data) {
    return data[STEPS.OBJECTIVE].objective !== OBJECTIVES.MY_TEAM;
  }

  render() {
    return html`
      <nova-flow @completed=${this._completedFlow}>
        <nova-flow-step title="Welcome!" step="${STEPS.START}" next-enabled>
          ${this.localize('view-client-onboarding.welcomeMessage')}
        </nova-flow-step>
        <nova-flow-step title="Let's curate your experience with Skillswave!" step="${STEPS.OBJECTIVE}">
          <client-onboarding-objective></client-onboarding-objective>
        </nova-flow-step>
        <nova-flow-step title="Is this ${this._companyName}'s industry?" step="${STEPS.INDUSTRY}">
          <client-onboarding-industry lightcast-company-id="${this._lightcastCompanyId}"></client-onboarding-industry>
        </nova-flow-step>
        <nova-flow-step title="At ${this._companyName} what team are you trying to empower?" step="${STEPS.DEPARTMENT}" .shouldSkip=${this._shouldSkipDepartment}>
          <client-onboarding-department lightcast-company-id="${this._lightcastCompanyId}"></client-onboarding-department>
        </nova-flow-step>
        <nova-flow-step title="Let's personalize the ${this._companyName} team's learning experience!" step="${STEPS.FOCUS}" next-enabled>
          <client-onboarding-focus></client-onboarding-focus>
        </nova-flow-step>
        <nova-flow-step title="Thank you!" step="${STEPS.END}" next-enabled>
          You are all done!
        </nova-flow-step>
      </nova-flow>
    `;
  }
}

window.customElements.define('view-client-onboarding', ViewClientOnboarding);
